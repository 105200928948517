var config = {};
config.AppKey = 'B774DB5F-025B-4644-8C84-567E06C2AD17';
config.PersonaAppKey='11787BEC-57D2-44F3-BA32-F6E12435703B';
config.MexicoAppKey = '8CCE809C-ABBD-473F-A335-4CF8D8D0F9AF';
config.serviceURL = 'https://ccxapi.arifleet.com/api/';//http://localhost:60980/api/'; //'http://mtlwdvintxiis01:92/api/'; //'http://192.168.150.217:82/api/';
config.logServiceURL='http://localhost:60980/api/';//http://mtlwdvintxiis01:92/api/';
config.shopViewRefreshFrequencyInMinutes = 10;
config.sessionExpirationTimeInMinutes=30; /*session expiration time in minutes*/
config.sessionExpirationCheckFrequencyInMinutes = 1;
config.taxName = 'Standard';
config.searchDelay=400;
config.throttledSaveStateInSeconds = 3;
config.recentServicePageSize = 5; 
config.repairHistoryPageSize = 25;
config.supplierSiteLink = "https://suppliers.arifleet.com/default.aspx?referral=APC";
config.enableVMSLogging = false; 
config.reCaptchaKey="6LcTbFkeAAAAANx3vJ4_wT12keAW9wt-HksnvK03";
config.AccmanApiUrl = 'https://api.holman.solutions/accmanV2/';
config.accidentAssignmentsViewRefreshFrequencyInMinutes = 10;
config.accmanConcurentImageUpload = 5;
/*
//These are the keys for the different environments for recaptcha
config.reCaptchaKeyLocal='6Lcia1keAAAAAH0seHi8t5JKeHsVo37F6ZdDO0pc';
config.reCaptchaKeyDevelopment='6LcEbFkeAAAAACJVyz0pCQ_W9WI7VpYkYKzfE6Q1';
*/
config.holmanPartnerConnectRedirectUrl="https://partnerconnect.holman.com";
config.showHolmanRedirectScreen="disabled";
config.version="187.1.591";

module.exports = config;

